import * as _detectLibc2 from "detect-libc";
var _detectLibc = "default" in _detectLibc2 ? _detectLibc2.default : _detectLibc2;
import _pkg from "../pkg";
import _browserslistToTargets from "./browserslistToTargets";
import _composeVisitors from "./composeVisitors";
import _flags from "./flags";
import _process from "process";
var exports = {};
var process = _process;
let parts = [process.platform, process.arch];
if (process.platform === "linux") {
  const {
    MUSL,
    family
  } = _detectLibc;
  if (family === MUSL) {
    parts.push("musl");
  } else if (process.arch === "arm") {
    parts.push("gnueabihf");
  } else {
    parts.push("gnu");
  }
} else if (process.platform === "win32") {
  parts.push("msvc");
}
if (process.env.CSS_TRANSFORMER_WASM) {
  exports = _pkg;
} else {
  try {
    exports = require(`lightningcss-${parts.join("-")}`);
  } catch (err) {
    exports = require(`../lightningcss.${parts.join("-")}.node`);
  }
}
exports.browserslistToTargets = _browserslistToTargets;
exports.composeVisitors = _composeVisitors;
exports.Features = _flags.Features;
export default exports;
export const browserslistToTargets = exports.browserslistToTargets,
  composeVisitors = exports.composeVisitors,
  Features = exports.Features;